// Need to hoist public path setting to top so ES6 imports do not come before it.
import './publicPath';

// eslint-disable-next-line node/no-unpublished-import
import {Auth} from '@verily-src/auth';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

// This is used to expose PHAF global declarations.
import '@verily-src/phaf-runtime-helpers';

import {config} from '@verily-src/phaf-runtime-helpers/src/mfe_helpers/configurationWrapper';
import ErrorPage from './components/ErrorPage/ErrorPage';
import {isLocal} from './dev';
import {getRoot} from './Root';

if (isLocal && window.location.host.includes('localhost')) {
  const domain = config.getString('AUTH0_DOMAIN');
  const clientId = config.getString('AUTH0_CLIENT_ID');
  const audience = config.getString('AUTH0_AUDIENCE');
  Auth.setUpClient(domain, clientId, window.location.origin, audience);
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  loadRootComponent: getRoot,
  errorBoundary() {
    return <ErrorPage />;
  },
});

export const {bootstrap, mount, unmount} = lifecycles;
