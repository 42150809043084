import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  type ProviderProps,
} from 'react';

// eslint-disable-next-line node/no-unpublished-import
import {DEFAULT_LOCALE, Locale} from '../messages';
import type {LocalizedMessage, LocalizedMessageArgs} from './types';
import {useNormalizedLocale} from './util';

type ContentProviderContext = {
  localeId: number;
};

type ContentProviderProps = {
  children: ProviderProps<ContentProviderContext>['children'];
  locale: string;
};

const ContentContext = createContext<ContentProviderContext>(null as never);

export const ContentProvider = (props: ContentProviderProps) => {
  const {children, locale} = props;
  const normalizedLocale = useNormalizedLocale(locale);
  const [localeId, setLocaleId] = useState(Locale[normalizedLocale]);
  const contextValue = useMemo(() => ({localeId}), [localeId]);

  useEffect(() => {
    setLocaleId(Locale[normalizedLocale]);
  }, [normalizedLocale]);

  return (
    <ContentContext.Provider value={contextValue}>
      {children}
    </ContentContext.Provider>
  );
};

export const useMessage = <T extends LocalizedMessageArgs>(
  message: LocalizedMessage<T>
) => {
  const context = useContext(ContentContext);

  return (...args: T) =>
    message(...args)[context?.localeId] || message(...args)[DEFAULT_LOCALE];
};
