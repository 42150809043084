import {Box, Typography} from '@mui/material';
import {Button} from '@verily-src/react-design-system';
import {
  errorPageMainText,
  errorPageTryLaterText,
  refreshButtonText,
  useMessage,
} from '@verily-src/survey-localization';
import React from 'react';

import ErrorImage from './ErrorImage';

const ErrorPage = () => {
  const errorPageMainMsg = useMessage(errorPageMainText);
  const errorPageTryLaterMsg = useMessage(errorPageTryLaterText);
  const refreshButtonMsg = useMessage(refreshButtonText);

  return (
    <Box sx={{textAlign: 'center'}}>
      <Box sx={{p: 7}}>{ErrorImage}</Box>
      <Typography variant='h4' component='h2' sx={{mb: 2}}>
        {errorPageMainMsg()}
      </Typography>
      <Typography variant='body1'>
        {/* TODO: Translate this message. */}
        {errorPageTryLaterMsg()}
      </Typography>
      <Button
        onClick={() => {
          window.location.reload();
        }}
      >
        {refreshButtonMsg()}
      </Button>
    </Box>
  );
};

export default ErrorPage;
