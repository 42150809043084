import {useMemo} from 'react';
// eslint-disable-next-line node/no-unpublished-import
import {DEFAULT_LOCALE, Locale} from '../messages';

type SupportedLocale = keyof typeof Locale & string;

const defaultLocale = Object.keys(Locale).find(
  locale => Locale[locale as SupportedLocale] === DEFAULT_LOCALE
) as SupportedLocale;

const createFallbackMaps = () => {
  const [defaultLanguage, defaultRegion] = defaultLocale.split('-');
  const languageFallback: Record<string, SupportedLocale> = {
    [defaultLanguage]: defaultLocale,
  };
  const regionFallback: Record<string, SupportedLocale> = defaultRegion
    ? {
        [defaultRegion]: defaultLocale,
      }
    : {};

  // Iterate through the list of locales to built the fallback lookup maps.
  // Assume the first defined locale for a given language or region is the
  // fallback.
  for (const locale in Locale) {
    const [language, region] = locale.split('-');
    if (!languageFallback[language]) {
      languageFallback[language] = locale as SupportedLocale;
    }
    if (!regionFallback[region]) {
      regionFallback[region] = locale as SupportedLocale;
    }
  }

  return {
    languageFallback,
    regionFallback,
  };
};

const {languageFallback, regionFallback} = createFallbackMaps();

const normalizeLocale = (locale: string): SupportedLocale => {
  const components = locale.split('-');
  if (components.length > 2) {
    return defaultLocale;
  }
  const language = components[0].toLowerCase();
  const region = components[1]?.toUpperCase();

  const normalizedLocale = `${language}${region ? `-${region}` : ''}`;
  if (normalizedLocale in Locale) {
    return normalizedLocale as SupportedLocale;
  }
  // If the provided locale is not in the list of supported locales, the order
  // of fallback is:
  // 1. The fallback locale for the provided locale's language
  // 2. The fallback locale for the provided locale's country
  // 3. The default locale
  // This is overly simplistic and will likely need to be improved upon in the
  // future.
  return languageFallback[language] ?? regionFallback[region] ?? defaultLocale;
};

/** Returns the supported locale of best fit for the given locale string */
export const useNormalizedLocale = (locale: string): SupportedLocale =>
  useMemo(() => normalizeLocale(locale), [locale]);
