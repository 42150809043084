import React from 'react';
import type {AppProps} from 'single-spa';

import type {Root} from '../Root.types';

const getRoot = async (props: Partial<AppProps> = {}) => {
  let Root: Root = () => null;
  // Should check if verily me, but Unified API package will not work since it
  // tries to export and enum but only exposes types.
  Root = (await import('../VerilyMeRoot')).default;
  return () => <Root {...props} />;
};

export default getRoot;
